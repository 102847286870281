<template>
    <v-dialog
        max-height="300px"
        max-width="450px"
        :value="dlgShow"
        overlay-opacity="0.5"
        :retain-focus="false"
    >
        <v-card class="dlg-container">
            <v-card-title>
                <v-row>
                    <v-col cols="10" class="dlg-header">
                        {{ dlgHeader }}
                    </v-col>
                    <v-col cols="2" class="text-right">
                        <CustomButton
                            btnIcon="mdi-close"
                            tooltipMsg="Close Dialog"
                            @click="$emit('closeDialog')"
                        />
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text class="dlg-body">
                <v-row>
                    <v-col>
                        <p class="question">
                            <slot name="question">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book
                            </slot>
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="dlg-action">
                <v-row>
                    <v-col class="text-right">
                        <CustomButton
                            btnLabel="Cancel"
                            tooltipMsg="Cancel"
                            @click="$emit('actionCancel')"
                        />
                        <CustomButton
                            :btnColour="dlgConfirmColour"
                            :btnIsLoading="dlgLoading"
                            :btnLabel="dlgConfirmMessage"
                            @click="$emit('actionConfirm')"
                        />
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    data: () => ({}),
    name: "customActionConfirmationDialog",
    props: {
        dlgConfirmColour: { default: "primary", type: String },
        dlgConfirmMessage: { default: "OK", type: String },
        dlgHeader: { default: "Custom Header" },
        dlgPersistent: { default: false, type: Boolean },
        dlgShow: { default: false, type: Boolean },
        dlgLoading: { default: false, type: Boolean },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

<style scoped>
.dlg-action {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 16px !important;
}
.dlg-body {
    /* height: 200px; */
}
.dlg-container {
    /* height: 200px; */
}
.dlg-header {
    font-size: 1.2rem;
    font-weight: 400;
}
.question {
    font-size: 1rem;
}
</style>

